export default function executionsService(api) {
  return {
    getExecution: async function (handle, projectKey, id) {
      return api.get(`/${handle}/projects/${projectKey}/executions/${id}`);
    },
    searchExecutions: async function (handle, projectKey ,text, perPage, currentPage) {
      return api.get(`/${handle}/projects/${projectKey}/executions/search?query=${text}&per_page=${perPage}&current_page=${currentPage}`);
    },
    updateExecution: async function(handle, projectKey, id, data){
      return api.patch(`/${handle}/projects/${projectKey}/executions/${id}`, data);
    },
    deleteExecutions: async function (handle, projectKey, item) {
      return api.delete(`/${handle}/projects/${projectKey}/executions/${item}`);
    },
  };
}
