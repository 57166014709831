<template>
  <v-container id="case-index-container" class="fill-height" fluid>
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <div class="d-flex flex-row justify-space-between">
          <div class="d-flex flex-row align-center pointer" @click="handleBackClick">
            <v-icon color="black">mdi-arrow-left</v-icon>
            <h2 class="d-flex-inline justify-center align-center ml-2 ma-0 font-weight-bold">
              {{ name }}
            </h2>
            <div class="d-flex flex-row align-center justify-space-between ml-6">
              <v-progress-linear
                rounded
                color="#0c2ff3"
                background-color="#F2F4F7"
                class="custom-progressbar"
                :height="8"
                :value="progress"
              />
              <span class="font-weight-regular ml-3">{{progress}}%</span>
            </div>
          </div>
          <div class="d-flex flex-row">
            <v-btn
              dark
              large
              color="gray-100"
              class="text-capitalize font-weight-bold black--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
              @click="duplicateTestRun"
            >
              {{ $t('testruns.rerun') }}
            </v-btn>
            <v-btn
              dark
              large
              color="blue"
              class="text-capitalize font-weight-bold mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
              @click="completeTestRun"
            >
              {{ $t('testruns.complete') }}
            </v-btn>
          </div>
      </div>
    </v-card>
    <ExecutionManagement
      :showCreate="false"
      @bulkRemove="onBulkRemove"
      :execution="selectedExecution"
      @getExecution="getExecution"
      @folder-select="getFolder"
      @updateExecution="updateTestExecution"
      @updateSelectedExecution="updateSelectedExecution"
      :executions="testRunExecutions"
      :assignees="assignees"
      :testResults="testResults"
      @updateResult="updateTestResult"
      @deleteResult="deleteTestResult"
      @addResult="addTestResult"
      @moveItem="moveSelectedItem"
    ></ExecutionManagement>
  </v-container>
</template>

<script>
// import SectionHeader from '@/components/TestCases/SectionHeader.vue';
import ExecutionManagement from '@/components/Execution/ExecutionManagement'
 import { createNamespacedHelpers } from 'vuex';
import makeRunService from '@/services/api/run';
import makeExecutionService from '@/services/api/execution'
import makeResultService from '@/services/api/result'
import axios from 'axios';
import makeAttachment from '@/services/api/attachment';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
const { mapActions } = createNamespacedHelpers('run');

export default {
  props: {
    CustomCases : Array,
    progress: Number,
    name: String
  },
  data() {
    return {
      selectedCases: [],
      testRunExecutions: [],
      folderId: null,
      selectedExecution: null,
      assignees: [],
      testResults: []
    };
  },
  components:{
    ExecutionManagement
  },
  methods: {
    ...mapActions(['updateTestRuns']),
    handleBackClick() {
      //this.showConfirmBackDialog = true;
      this.$router.replace({
        name: 'Runs',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
      });
    },
    completeTestRun() {
      let payload = this.selectedCases.map(({externalId,customFields, source, uid, name }) => ({externalId: externalId,customFields, source, uid, name, completed: true}));
      this.updateTestRuns({
        swal: this.$swal,
        handle: this.$route.params.handle,
        projectKey: this.$route.params.key,
        uid: null,
        payload: { runs: payload }
      })
        .then(() => {
          this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
        })
        .catch(error => {
          console.error("Failed to Update Test Run:", error);
        });
    },
    async duplicateTestRun(){
      const runService = makeRunService(this.$api);

      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const runId = this.$route.params.id;

      await runService.duplicateTestRun(handle, projectKey, runId).then(response => {
        showSuccessToast(this.$swal, this.$t('testruns.duplicate_testrun.success'))
        this.$router.push({
            name: 'Runs',
            params: {
              handle: this.$route.params.handle,
              key: this.$route.params.key
            },
          });
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('testruns.duplicate_testrun.error'))
      })
    },
    async getTestRunExecutions(handle, projectKey, runId){
      const runService = makeRunService(this.$api);
      await runService.getTestRunExecutions(
        handle,
        projectKey
        ,runId
      ).then(response => {
        this.testRunExecutions = response.data.executions;
        this.assignees = response.data.assignees;
      })
    },
    async getFolder(folderId){
      this.folderId = folderId;
    },
    async getExecution(executionId){
      await this.getExecutionResult(executionId)
      this.selectedExecution = this.testRunExecutions.find(element => element.uid == executionId)
    },
    updateSelectedExecution(selectedCases) {
      this.selectedCases = selectedCases;
    },
    async getExecutionResult(executionUid){
      const handle = this.$route.params.handle;
      const resultService = makeResultService(this.$api);
      const projectKey = this.$route.params.key; 

      await resultService.getTestResults(handle, projectKey, executionUid).then(response => {
        this.testResults = response.data;
      })
      
    },
    onBulkRemove(payload){
      console.log("Delete from Test Run", payload)
    },
    async updateTestExecution(payload){
      const executionService = makeExecutionService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution.uid;
      await executionService.updateExecution(handle,projectKey,selectedExecution,payload).then(() => {
        this.selectedExecution = {...this.selectedExecution,...payload}
        showSuccessToast(this.$swal, this.$t('success.executionUpdated'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.executionUpdateFailed'))
      })
    },
    async moveSelectedItem(direction){
      let itemIndex = this.testRunExecutions.findIndex(element => element.uid == this.selectedExecution.uid)
      if(direction == 'next' && itemIndex < this.testRunExecutions.length-1)
        this.selectedExecution = this.testRunExecutions[itemIndex + 1]
      else if(direction == 'previous' && itemIndex > 0)
        this.selectedExecution = this.testRunExecutions[itemIndex - 1]

      await this.getExecutionResult(this.selectedExecution.uid)
    },
    async uploadToServer(attachmentService, handle, file) {
      const { data } = await attachmentService.getSignedAttachmentUrl(handle,file);
      await this.uploadFile(file, data.upload_url, data.client_headers);
      return data.objectUrl;
    },
    async uploadFile(file, signedUrl, headers) {
      try {
        await axios.put(signedUrl, file, {
          headers,
          onUploadProgress: (progressEvent) => {
            const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
        });
      } catch (error) {
        // Handle error
      }
    },
    async addTestResult(data){

      const attachments = data.files.map(element => {
        return{
          fileName: element.name,
          relatedTo: 'result', 
          size: element.size,
          type: element.type,
          mediaType: 'attachment'
        }
      })

      
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const selectedExecution = this.selectedExecution.uid;
      const attachmentService = makeAttachment(this.$api);

      const payload = {
        status: data.status,
        comment: data.comment
      }


      await resultService.addTestResult(handle, projectKey, selectedExecution, payload).then(async (response) => {
        const result = response.data;
        if(attachments.length)
         await Promise.all(attachments.forEach(async attachment => {
          attachment.relatedToUid = result.uid
          await this.uploadToServer(attachmentService,handle,attachment)
        })).then(() => {
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
        })
        else
          showSuccessToast(this.$swal, this.$t('success.testResultAdded'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToAddTestResult'))
      })
      
      await this.getExecutionResult(selectedExecution)
    },
    async updateTestResult(resultUid,payload){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      
      await resultService.updateTestResult(handle, projectKey, resultUid, payload).then( () => {

        showSuccessToast(this.$swal, this.$t('success.testResultUpdated'))
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults[resultIndex].status = payload.status[0].toUpperCase()+payload.status.slice(1)
        this.testResults[resultIndex].comment = payload.comment;
        
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToUpdateTestResult'))
      })
    },
    async deleteTestResult(resultUid){
      const resultService = makeResultService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      
      await resultService.deleteTestResult(handle, projectKey, resultUid).then(() => {
        showSuccessToast(this.$swal, this.$t('success.testResultDeleted'));
        const resultIndex = this.testResults.findIndex(element => element.resultUid == resultUid)
        this.testResults.splice(resultIndex,1)
      }).catch( () => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteTestResult'))
      } )
    }
  },
  async created(){
      await this.getTestRunExecutions(
        this.$route.params.handle,
        this.$route.params.key,
        this.$route.params.id,
      )
  },
};
</script>
<style>
  .custom-progressbar{
    width: 200px;
  }
</style>
